import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Questionnaire from "@/views/Questionnaire.vue";
import Test from "@/views/Test.vue";
import LastStep from "@/views/LastStep.vue";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import QuestionnaireIOS from "@/views/QuestionnaireIOS.vue";
import QuestionnaireAndroid from "@/views/QuestionnaireAndroid.vue";
import TestAndroid from "@/views/TestAndroid.vue";
import TestIOS from "@/views/TestIOS.vue";
const detectDeviceService = new DetectDeviceService();

function detectDevice(): string {
  const isMobile = detectDeviceService.detectIsMobile();
  const isIpad = detectDeviceService.checkDesktopIPad();
  if (isIpad) {
    return 'ios';
  }
  if (!isMobile) {
    return 'desktop';
  } else {
    const isIOS = detectDeviceService.detectIsIOS();
    if (isIOS) {
      return 'ios';
    }
    return 'android';
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: detectDevice() === 'desktop' ? Questionnaire : detectDevice() === 'ios' ? QuestionnaireIOS : QuestionnaireAndroid
  },
  {
    path: '/test',
    name: 'Test',
    component: detectDevice() === 'desktop' ? Test : detectDevice() === 'ios' ? TestIOS : TestAndroid
  },
  {
    path: '/finish',
    name: 'Finish',
    component: LastStep,
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router
