import {Answer, ContentDescription, FullQuestion, QuestionTypeEnum} from "@/schema/content.model";

export default class RestoreService {
    restoreData(place): {blocks: Array<Array<ContentDescription | FullQuestion>>, visibleBlocks: Array<Array<ContentDescription | FullQuestion>>, savedAnswers: Answer[]} {
        let savedAnswers = JSON.parse(localStorage.getItem(place + 'Answers'));
        const blocks = JSON.parse(localStorage.getItem(place + 'Blocks'));
        let visibleBlocks: Array<Array<ContentDescription | FullQuestion>> = [];

        if (savedAnswers) {
            visibleBlocks = [...blocks.slice(0, savedAnswers.length)];

            if (savedAnswers.length > 1) {
                if (!savedAnswers[savedAnswers.length - 1].answerHtml && !savedAnswers[savedAnswers.length - 2].answerHtml) {
                    visibleBlocks.pop();
                }
            }

            visibleBlocks.forEach((block, blockIndex) => {
                block.forEach((blockElement, blockElementIndex) => {
                    if ('activated' in blockElement) {
                        (<FullQuestion>blockElement).activated = true;
                        (<FullQuestion>blockElement).answer = savedAnswers[blockIndex].answerHtml;

                        if (blockIndex + 1 === visibleBlocks.length && (<FullQuestion>blockElement).answer.length === 0) {
                            (<FullQuestion>blockElement).activated = false;
                        }
                    }
                })
            });
        } else {
            savedAnswers = [];
        }

        return {
            blocks,
            visibleBlocks,
            savedAnswers
        }
    }
}