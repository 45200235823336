import DetectDeviceService from "@/core/middlewares/detect-device.middleware";

const detectDeviceService = new DetectDeviceService();

export default class AnimationService {
    detectScrollDownValue(browser, question, clientY): number {
        const questionHeight = question.offsetHeight;
        const rect = question.getBoundingClientRect();
        const A = clientY - rect.top;
        const B = questionHeight - A;
        let additional = 0;
        if (browser !== 'Chrome' && browser !== 'Opera') {
            additional += 2;
        }
        if (browser === 'Opera') {
            additional += 1;
        }
        return B + 20 + additional;
    }

    getAdditionalIOS15Value(): number {
        const appVersionString = navigator.appVersion;
        const device = appVersionString.includes('Android') ? 'android' : 'ios';
        if (device !== 'android') {
            const iOSPosition = appVersionString.indexOf('OS');
            const versionString = appVersionString.substring(iOSPosition);
            const version = versionString.match(/\d+/)[0];
            if (+version >= 15) {
                const isSafari = appVersionString.indexOf('Safari');
                if (isSafari !== -1) {
                    const isIPhoneWithButton = detectDeviceService.detectIsIPhoneWithButton();
                    if (isIPhoneWithButton === true) {
                        return 20;
                    } else {
                        return 60;
                    }
                }
            }
        }
        return 0;
    }

    getRiseUpValue(visibleBlocksLength: number): null | number {
        const lastBlock = document.querySelector('.block_last-visible') as HTMLElement ;
        if (lastBlock) {
            const lastBlockIndex = lastBlock.parentElement.getAttribute('block-index');
            const rect = lastBlock.getBoundingClientRect();
            const questionHeight = lastBlock.offsetHeight;
            const additionalHeight = this.getAdditionalIOS15Value();

            if (rect.y >= window.innerHeight - questionHeight - 30 - additionalHeight && rect.top < window.innerHeight + questionHeight * 2) {
                const scrollAmount = rect.y - window.innerHeight + questionHeight + 30 + additionalHeight;
                return scrollAmount;
                if (+lastBlockIndex + 1 === visibleBlocksLength) {
                    // return scrollAmount;
                }
            }
            return null;
        }
    }
}
