
import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {ContentDescription, FullQuestion} from "@/schema/content.model";
import ContentTextBlock from "@/shared/components/ContentTextBlock.vue";
import Question from "@/shared/components/Question.vue";

@Options({
  components: {
    ContentTextBlock,
    Question
  }
})
export default class ContentBlock extends Vue {
  questionAnswerValid: boolean = false;
  isActive: boolean = false;

  @Prop({type: Array, default: null}) block: Array<ContentDescription | FullQuestion>;
  @Prop({type: Number, default: null}) index: number;
  @Prop({type: Number, default: null}) totalLength: number;
  @Prop({type: Number, default: null}) visibleBlocksLength: number;

  emitQuestionActivation(questionData): void {
    this.$emit('question-activated', questionData);
  }

  getQuestionAnswer(value: FullQuestion): void {
    this.$emit('content-block-valid', {index: this.index, question: value});
  }

  getQuestionError(value: {id: number, answer: string}): void {
    this.$emit('content-block-invalid', {index: this.index, question: value});
  }

  throwActiveQuestionIndex(): void {
    this.isActive = true;
    this.$emit('active-question-index', this.index)
  }
  // emitEmptyQuestionLostFocus(questionHeight: number): void {
  //   this.$emit('invalid-block-lost-focus', questionHeight)
  // }
}
