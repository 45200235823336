export default class MobileContentService {
    createContentString(content: string): string {
        const div = document.createElement('div');
        div.innerHTML = `
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
                * {
                  box-sizing: border-box;
                  margin: 0;
                  padding: 0;
                }
                a, p, span, li, button, input, textarea, label, form, h1, h2, h3, h4, h5, h6, div {
                  font-family: 'Ubuntu', sans-serif;
                  color: #161616;
                  text-align: left;
                }
                .flex {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                .flex_column {
                    flex-flow: column nowrap;
                }
                .flex_nowrap {
                    flex-wrap: nowrap;
                }
                .items_start {
                    align-items: flex-start;
                }
                .items_center {
                    align-items: center;
                }
                .items_end {
                    align-items: flex-end;
                }
                .justify_start {
                    justify-content: flex-start;
                }
                .justify_center {
                    justify-content: center;
                }
                .justify_end {
                    justify-content: flex-end;
                }
                .justify_between {
                    justify-content: space-between;
                }
                .editor-content {
                    margin: 0;
                    height: auto;
                }
                h2.custom-heading {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: bold;
                }
                h3.custom-heading {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: bold;
                }
                .plain-text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    min-height: 20px;
                    display: block;
                }
                .link {
                    text-decoration: underline;
                }
                .block {
                    display: none;
                    opacity: 0;
                }
                .block_visible {
                    display: block;
                    opacity: 1;
                    margin: 32px 0 0 0;
                }
                .block_last-visible {
                    display: block;
                    opacity: 1;
                    margin: 32px 0 0 0;
                }
                .question__title {
                    display: block;
                    font-size: 14px;
                    color: #808080;
                    text-shadow: none;
                    width: 100%;
                    margin: 0;
                }
                .question__title_activated {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 27px;
                    color: #161616;
                    max-width: 500px;
                    margin: 0 0 8px 0;
                }
                .variants {
                    display: block;
                    height: 44px;
                    width: 100%;
                    padding: 4px 0 0 0;
                }
                .variant-button {
                    display: block;
                    text-align: center;
                    line-height: 35px;
                    align-items: center;
                    justify-content: center;
                    width: 55px;
                    height: 35px;
                    border: none;
                    background: #e8e8e8;
                    border-radius: 3px;
                    font-size: 14px;
                    color: #161616;
                    margin: 0 0 0 15px;
                    float: left;
                }
                .variant-button_first {
                    margin: 0;
                }
                .variant-button.variant-button_active {
                    background: #c2c2c2;
                    font-weight: bold;
                }
                .test-input {
                    display: block;
                    width: 0;
                    height: 0;
                    background: #ffffff;
                    border-color: #ffffff;
                }
            </style>
            ${content}
        `;
        return div.outerHTML;
    }
}
