
import {Options, Vue} from "vue-class-component";
import ResultsService from "@/core/services/results.service";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import QuestionnaireApiService from "@/core/services/questionnaire-api.service";
import {ContentBlock, FullQuestion} from "@/schema/content.model";
import TestApiService from "@/core/services/test-api.service";
import UIService from "@/shared/services/ui.service";

const resultsService = new ResultsService();
const detectDeviceService = new DetectDeviceService();
const questionnaireApiService = new QuestionnaireApiService();
const testApiService = new TestApiService();
const uiService = new UIService();

@Options({
  computed: {
  }
})
export default class App extends Vue {
  errorMessage: null | {title: string, message: string} = null;
  isMobile = detectDeviceService.detectIsMobile();
  isLandscape = true;
  isIpad = false;

  downloadData(): Promise<boolean> {
    const qBlocksFromStorage = JSON.parse(localStorage.getItem('questionnaireBlocks'));
    const tBlocksFromStorage = JSON.parse(localStorage.getItem('testBlocks'));
    const qTotalLength = +localStorage.getItem('questionnaireTotalLength');
    const tTotalLength = +localStorage.getItem('testTotalLength');

    return new Promise((resolve, reject) => {
      if (qBlocksFromStorage && tBlocksFromStorage) {
        if (qBlocksFromStorage.length === qTotalLength && tBlocksFromStorage.length === tTotalLength) {
          resolve(true);
        } else {
          localStorage.removeItem('questionnaireBlocks');
          localStorage.removeItem('testBlocks');
          this.downloadData();
        }
      } else {
        const apiCalls = [questionnaireApiService, testApiService];
        const requests = apiCalls.map(service => service.downloadBlocks());
        // @ts-ignore
        return Promise.all(requests).then(responses => responses.forEach(
            (response, index) => {
              const blocks = response.data.blocks;
              const total = response.data.total;
              const direction = index === 0 ? 'questionnaire' : 'test';
              if (blocks && blocks.length > 0) {
                localStorage.setItem(`${direction}Blocks`, JSON.stringify(this.flatBlocks(blocks)));
              }
              if (total) {
                localStorage.setItem(`${direction}TotalLength`, JSON.stringify(total));
              }
              resolve(true);
            }
        )).catch(err => {
          if (err.response.status === 500) {
            this.blockApp({title: 'При загрузке задания произошла ошибка', message: 'Попробуйте обновить страницу или воспользоваться сервисом позже.'})
          }
        });
      }
    })
  }

  flatBlocks(blocks: ContentBlock[]): any[] {
    const blocksToArray = [];
    blocks.forEach(block => {
      const modifiedQuestions: FullQuestion[] = [];
      block.questions.forEach(question => {
        question.answer = '';
        question.activated = false;
        modifiedQuestions.push(question);
      })
      const flattenBlock = [...block.contents, ...modifiedQuestions];
      blocksToArray.push(flattenBlock);
    });
    return blocksToArray;
  }

  blockApp(message?: {title: string, message: string}): void {
    uiService.removePreloader();
    if (message) {
      this.errorMessage = {
        title: message.title,
        message: message.message
      }
    }
  }

  checkCookie(): Promise<void> {
    return new Promise((resolve, reject) => {
      const isCookieEnabled = navigator.cookieEnabled;
      if (!isCookieEnabled) {
        reject();
      } else {
        resolve();
      }
    })
  }

  checkLocalStorage(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (typeof localStorage !== 'undefined') {
        try {
          localStorage.setItem('feature_test', 'yes');
          if (localStorage.getItem('feature_test') === 'yes') {
            localStorage.removeItem('feature_test');
            resolve();
          } else {
            reject();
          }
        } catch(e) {
          reject();
        }
      } else {
        reject();
      }
    })
  }

  checkDesktopIPad(): boolean {
    const isIpad = detectDeviceService.checkDesktopIPad();
    this.isMobile = isIpad;
    this.isIpad = isIpad;
    return isIpad;
  }

  checkResultId(): Promise<string | boolean> {
    const resultId = resultsService.isResultExists();

    return new Promise((resolve, reject) => {
      if (!resultId) {
        this.clearLocalStorage();
        this.createResultId().then(response => {
          const resultId = response.data.resultId;
          if (resultId) {
            localStorage.setItem('resultId', JSON.stringify(resultId));
            resultsService.putResultIdToHeaders(resultId);
            this.setCookie(resultId);
            resolve(resultId);
          } else {
            reject(false);
          }
        }).catch(err => {
          reject(err.response.status.toString())
        })
      } else {
        resultsService.putResultIdToHeaders(resultId);
        this.setCookie(resultId);
        resolve(resultId);
      }
    })
  }

  createResultId(): Promise<any> {
    let testId = window.location.pathname.slice(1);
    if (testId === 'finish' || testId === 'test' || testId ==='questionnaire' || testId === 'tmobile' || testId === 'qmobile') {
      testId = '';
    }
    return resultsService.createResult(testId);
  }

  checkResetDate(): Promise<boolean> {
    const today = new Date();
    const resetDateFromStorage = JSON.parse(localStorage.getItem('resetDate'));

    return new Promise((resolve, reject) => {
      if (resetDateFromStorage) {
        const expiredDate = new Date(resetDateFromStorage);
        if (expiredDate <= today) { // Expired
          this.clearLocalStorage();
          location.reload();
        } else { // Not expired
          resolve(true);
        }
      } else {
        let newResetDate = new Date();
        newResetDate.setDate(today.getDate() + 5);
        localStorage.setItem('resetDate', JSON.stringify(newResetDate));
        resolve(true);
      }
    })
  }

  checkProgress(): void {
    const progress = localStorage.getItem('progress');
    switch(progress) {
      case('D'):
        this.$router.push({
          name: 'Finish'
        });
        break;
      case('F'):
        this.$router.push({
          name: 'Finish'
        });
        break;
      case('Q'):
        this.downloadData().then(() => {
          // TODO: remove it later, when app will not have any routes
          this.$router.push({
            name: 'Questionnaire'
          });
        })
        break;
      case('T'):
        this.downloadData().then(() => {
          // TODO: remove it later, when app will not have any routes
          this.$router.push({
            name: 'Test'
          });
        })
        break;
      default:
        this.downloadData().then(() => {
          localStorage.setItem('progress', 'Q');
          // TODO: remove it later, when app will not have any routes
          this.$router.push({
            name: 'Questionnaire'
          });
        })
        break;
        // TODO: Logic to show finish component (will be later)
    }
  }

  clearLocalStorage(): void {
    window.localStorage.clear();
  }

  setCookie(resultId: string): void {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    const lastDotPosition = window.location.host.lastIndexOf('.');
    const secondDotPosition = window.location.host.lastIndexOf('.', lastDotPosition - 1);
    let domain;
    if (secondDotPosition === -1) {
      domain = '.' + window.location.host;
    } else {
      domain = window.location.host.slice(secondDotPosition);
    }
    document.cookie = `pstf_ri=${resultId}; expires=${today}; domain=${domain}; path=/;`
  }

  controlDesktop(): void {
    if (window.innerWidth < 768 || window.innerHeight < 350) {
      uiService.removePreloader();
      this.errorMessage = {
        title: 'Слишком маленький размер экрана',
        message: 'Пожалуйста, растяните окно браузера, чтобы пользоваться сайтом было комфортно.'
      }
    } else {
      this.errorMessage = null;
    }
  }

  checkLandscape(): void {
    setTimeout(() => {
      const isIOS = detectDeviceService.detectIsIOS();
      let mobileLandscape;
      if (!isIOS) {
        mobileLandscape = screen.height < screen.width;
      } else {
        mobileLandscape = window.innerWidth > window.innerHeight;
      }
      this.isLandscape = mobileLandscape;
      if (mobileLandscape) {
        this.errorMessage = {
          title: 'Поверните устройство',
          message: 'При горизонтальной ориентации пользоваться приложением неудобно.'
        }
        uiService.removePreloader();
      } else {
        this.errorMessage = null;
      }
    }, 100);
  }

  created(): void {
    this.checkCookie().then(() => {
      this.checkLocalStorage().then(() => {
        this.checkResultId().then(() => {
          this.checkResetDate().then(() => {
            this.checkProgress();
          })
        }, (err) => {
          switch(+err) {
            case(404):
              this.blockApp({title: 'Тестовое задание не найдено', message: 'Возможно, тестовое задание было удалено или Вы ввели неправильную ссылку.'})
              break;
            case(409):
              this.blockApp({title: 'Задание для кандидата еще не готово', message: 'Попробуйте пройти тестовое задание позже.'})
              break;
            default:
              this.blockApp({title: 'Произошла ошибка', message: 'Мы уже знаем о ней и работаем над ее устранением. Попробуйте воспользоваться сервисом позже.'})
          }
        })
      }, () => {
        this.blockApp({title: 'Включите Local Storage', message: 'Чтобы воспользоваться сайтом, необходимо включить Local Storage в Вашем браузере.'});
      })
    }, () => {
      this.blockApp({title: 'Включите cookie', message: 'Чтобы воспользоваться сайтом, необходимо включить cookies в Вашем браузере.'});
    });
  }

  mounted(): void {
    let isMobile = detectDeviceService.detectIsMobile();
    if (!isMobile) {
      isMobile = this.checkDesktopIPad();
    }
    if (isMobile) {
      this.checkLandscape();
      window.addEventListener('resize', () => {
        this.checkLandscape();
      })
    } else {
      this.controlDesktop();
      window.addEventListener('resize', () => {
        this.controlDesktop();
      })
    }

    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
      document.body.style.zoom = '0.99';
    });

    document.addEventListener('gesturechange', function(e) {
      e.preventDefault();
      document.body.style.zoom = '0.99';
    });

    document.addEventListener('gestureend', function(e) {
      e.preventDefault();
      document.body.style.zoom = '0.99';
    });

    let lastTouchEnd = 0;
    const isIOS = detectDeviceService.detectIsIOS();
    const isIpad = detectDeviceService.checkDesktopIPad();
    if (isIOS || isIpad) {
      document.addEventListener('touchend', function (event) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);
    }
  }
}
