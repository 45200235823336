
import {Options, setup, Vue} from "vue-class-component";
import {Editor, BubbleMenu, EditorContent, Extension} from "@tiptap/vue-3";
import IconBase from "@/shared/components/IconBase.vue";
import IconHeading2 from "@/shared/components/icons/IconHeading2.vue";
import IconHeading3 from "@/shared/components/icons/IconHeading3.vue";
import IconTextBold from "@/shared/components/icons/IconTextBold.vue";
import IconLink from "@/shared/components/icons/IconLink.vue";
import IconTextItalic from "@/shared/components/icons/IconTextItalic.vue";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import History from "@tiptap/extension-history";
import {Prop, Watch} from "vue-property-decorator";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import {HardBreak} from "@tiptap/extension-hard-break";
import _ from 'lodash';

const detectDeviceService = new DetectDeviceService();

@Options({
  components: {
    EditorContent,
    BubbleMenu,
    IconBase,
    IconHeading2,
    IconHeading3,
    IconTextBold,
    IconLink,
    IconTextItalic,
  },
})
export default class TheEditor extends Vue {
  isIOS = false;

  preloadedEditorValue: string = '';
  @Prop({type: Boolean}) isBold: boolean;
  @Prop({type: String}) presetEditorValue: string;

  editor = null;
  iconSize: Number;
  isLinkEditorVisible = false;

  isBubbleVisible = false;

  switchLinkPopup(): void {
    if (this.editor.isActive('link')) {
      this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();
    } else {
      this.showLinkEditor()
    }
  }

  focusEditor(): void {
    if (!this.isIOS) {
      this.editor.view.dom.focus();
    }
  }

  showLinkEditor(): void {
    this.isLinkEditorVisible = true;
    this.$nextTick(() => {
      const linkInput = this.$refs.linkEditorInput as HTMLInputElement;
      linkInput.addEventListener('blur', (e) => {
        this.isLinkEditorVisible = false;
      })
      linkInput.focus();
    });
  }

  setLink(event: Event): void {
    event.preventDefault();
    const input = this.$refs.linkEditorInput as HTMLInputElement;
    let url = input.value;
    const isLinkCorrect = url.startsWith('http');
    if (!isLinkCorrect) {
      url = `http://${url}`;
    }
    this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({href: url})
        .updateAttributes({data_attr: url})
        .run();
    this.isLinkEditorVisible = false;
  }

  timerId!: any;

  debounceFunction  =  function (func, delay) {
    const that = this;
    clearTimeout(that.timerId)
    that.timerId = setTimeout(func, delay)
  }

  emitValue(): void {
    this.$emit('editor-threw-value', this.editor.getHTML())
  }

  created() {
    this.editor = new Editor({
      onCreate: () => {
        if (!this.presetEditorValue) {
          this.editor.commands.focus();
        } else {
          this.editor.commands.setContent(this.presetEditorValue);
        }
        if (this.isBold === true && !this.presetEditorValue) {
          setTimeout(() => {
            this.editor.chain().focus().toggleBold().run();
          })
        }
      },
      onUpdate: (e) => {
        if (e.transaction.getMeta('paste')) {
          this.emitValue();
          return;
        }

        const nodes = e.transaction.doc.content[Object.keys(e.transaction.doc.content)[0]];
        if (nodes.length === 1 && nodes[0].content.size === 0) {
          this.emitValue();
          return;
        }
        nodes.filter(node => node.content.size > 0).forEach(node => {
          node.content.size < 2 ? this.emitValue() : this.debounceFunction(this.emitValue, 3000);
          return;
        })
      },
      onBlur: (e) => {
        this.$emit('editor-threw-blur', this.editor.getHTML());
      },
      onSelectionUpdate: (e) => {
        this.isLinkEditorVisible = false;
        this.isBubbleVisible = true;
      },
      onFocus: () => {
        this.$emit('editor-threw-focus');
      },
      extensions: [
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: 'plain-text'
          }
        }),
        Text,
        Placeholder,
        Bold,
        Italic,
        Link.configure({
          HTMLAttributes: {
            class: 'link'
          }
        }),
        Heading.configure({
          levels: [2, 3],
          HTMLAttributes: {
            class: 'custom-heading'
          }
        }),
        BulletList.configure({
          HTMLAttributes: {
            class: 'bullet-list'
          }
        }),
        ListItem,
        History,
        HardBreak
      ]
    });
    this.iconSize = 26;
  }

  checkIsAnswerEmpty(value: string): boolean {
    const blockNode = document.createElement('html');
    blockNode.innerHTML = value;
    const valueNodes = Array.from(blockNode.getElementsByTagName('*')).slice(2);
    return valueNodes.every(this.isNodeEmpty);
  }

  isNodeEmpty(element) {
    return element.innerText.length === 0;
  }


  mounted() {
    const isMobile = detectDeviceService.detectIsMobile();
    const isIpad = detectDeviceService.checkDesktopIPad();
    this.isIOS = isMobile || isIpad;

    window.addEventListener('mousedown', (e) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains('container') || target.classList.contains('question__title') || target.tagName === 'DIV') {
        this.isBubbleVisible = false;
      }
    })
  }
}
