import axios from "axios";
import {Answer} from "@/schema/content.model";

export default class QuestionnaireApiService {
    downloadBlocks(): Promise<any> {
        return axios.get(`/questionnaire/blocks`);
    }
    publishQuestionnaire(content: string, answers: Answer[]): Promise<any> {
        return axios.post(`/questionnaire/done`, {content: content, answers: answers});
    }
    saveAnswersToStorage(answers: Answer[]): void {
        localStorage.setItem("questionnaireAnswers", JSON.stringify(answers));
    }
}
