import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3212077b")
const _hoisted_1 = ["block-index"]
const _hoisted_2 = { class: "block__content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentTextBlock = _resolveComponent("ContentTextBlock")!
  const _component_Question = _resolveComponent("Question")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", {'block_last-visible' : _ctx.index + 1 === _ctx.visibleBlocksLength,
       'block_last-hidden' : _ctx.index + 1 > _ctx.visibleBlocksLength,
       'block_visible' : _ctx.index + 1 < _ctx.visibleBlocksLength,
       'block_active' : _ctx.isActive}]),
    "block-index": _ctx.index
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block, (blockContent) => {
        return (_openBlock(), _createElementBlock("div", null, [
          (blockContent.content)
            ? (_openBlock(), _createBlock(_component_ContentTextBlock, {
                key: 0,
                textContent: blockContent.content
              }, null, 8, ["textContent"]))
            : _createCommentVNode("", true),
          (blockContent.answerVariants)
            ? (_openBlock(), _createBlock(_component_Question, {
                key: 1,
                question: blockContent,
                lastQuestion: _ctx.index + 1 === _ctx.visibleBlocksLength,
                onQuestionActivated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitQuestionActivation($event))),
                onQuestionValid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getQuestionAnswer($event))),
                onQuestionInvalid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getQuestionError($event))),
                onBlockActive: _cache[3] || (_cache[3] = ($event: any) => (_ctx.throwActiveQuestionIndex())),
                onBlockInactive: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isActive = false))
              }, null, 8, ["question", "lastQuestion"]))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ])
  ], 10, _hoisted_1))
}