import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-090b095e")
const _hoisted_1 = {
  class: "container",
  ref: "container"
}
const _hoisted_2 = {
  class: "test-input",
  type: "text",
  ref: "testInput",
  autocomplete: "on",
  autocapitalize: "on",
  spellcheck: "true"
}
const _hoisted_3 = {
  class: "layout__wrapper",
  ref: "layoutWrapper"
}
const _hoisted_4 = {
  class: "content__wrapper",
  ref: "contentWrapper"
}
const _hoisted_5 = { class: "content-block__wrapper" }
_popScopeId()

export function render(_ctx: any,_cache: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader, {
      class: "header",
      prevRoute: {name: 'Questionnaire'}
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: _normalizeClass(["header__next-button", {'header__next-button_disabled' : !_ctx.ableToPublish}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.publishTest()))
        }, "Далее", 2)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", _hoisted_2, null, 512),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_Transition, { name: "slide" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ContentBlock, {
                    block: block,
                    index: index,
                    totalLength: _ctx.totalLength,
                    visibleBlocksLength: _ctx.visibleBlocks.length,
                    onQuestionActivated: ($event: any) => (_ctx.activateQuestion($event, index)),
                    onContentBlockValid: ($event: any) => (_ctx.increaseVisibleBlocks($event, _ctx.blocks[index + 1])),
                    onContentBlockInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decreaseVisibleBlocks($event)))
                  }, null, 8, ["block", "index", "totalLength", "visibleBlocksLength", "onQuestionActivated", "onContentBlockValid"])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 256)),
          (_ctx.ableToPublish && _ctx.isMobile && _ctx.visibleBlocks.length === _ctx.blocks.length)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "footer__next-button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.publishTest()))
              }, "Далее"))
            : _createCommentVNode("", true)
        ], 512)
      ], 512)
    ], 512)
  ], 64))
}