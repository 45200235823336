
import {Options, Vue} from "vue-class-component";
import ButtonBack from "@/shared/components/ButtonBack.vue";
import {Prop} from "vue-property-decorator";
import router from "@/router";

@Options({
  components: {
  }
})
export default class TheHeader extends Vue {
  @Prop({type: Object, default: null}) prevRoute?: Object
  goBack() {
    if (this.prevRoute) {
      this.$router.push(this.prevRoute)
    }
  }
}
