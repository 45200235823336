export default class DetectDeviceService {
    detectIsMobile() {
        const isMobile = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
            },
            any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }
        };
        return !!isMobile.any();
    }

    detectIsIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    }

    detectOrientation(x: number, y: number): string {
        if (x > y) {
            return 'landscape';
        }
        return 'portrait';
    }

    detectBrowser() {
        if (navigator.userAgent.indexOf('OPR') != -1) {
            return 'Opera';
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return 'Chrome';
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            return 'Firefox';
        } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document['documentMode'] == true )) {
            return 'IE';
        } else {
            return 'Unknown';
        }
    }

    detectIsIPhoneWithButton(): boolean {
        const iHeight = window.screen.height;
        const iWidth = window.screen.width;

        if (iWidth === 320 && iHeight === 480) {
            return true;
        }
        else if (iWidth === 375 && iHeight === 667) {
            return true;
        }
        else if (iWidth === 414 && iHeight === 736) {
            return true;
        }
        else if (iWidth === 320 && iHeight === 568) {
            return true;
        }
        else if (iHeight <= 480) {
            return true;
        }
        return false;
    }

    is15IOS(): number {
        const appVersionString = navigator.appVersion;
        const device = appVersionString.includes('Android') ? 'android' : 'ios';
        if (device !== 'android') {
            const iOSPosition = appVersionString.indexOf('OS');
            const versionString = appVersionString.substring(iOSPosition);
            const version = versionString.match(/\d+/)[0];
            if (+version >= 15) {
                const isSafari = appVersionString.indexOf('CriOS');
                if (isSafari === -1) {
                    const isIPhoneWithButton = this.detectIsIPhoneWithButton();
                    if (isIPhoneWithButton === true) {
                        return 20;
                    } else {
                        return 60;
                    }
                }
            }
        }
        return 0;
    }

    checkDesktopIPad(): boolean {
        const navigatorPlatform = navigator.platform;
        const touchPoints = navigator.maxTouchPoints;
        if (navigatorPlatform && touchPoints) {
            if (navigatorPlatform === 'MacIntel' || navigatorPlatform === 'iPad') {
                if (touchPoints > 0) {
                    document.body.classList.add('body_ipad');
                    return true;
                }
            }
        }
        return false;
    }

}
