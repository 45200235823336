import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheEditor = _resolveComponent("TheEditor")!
  const _component_QuestionAnswerVariants = _resolveComponent("QuestionAnswerVariants")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["question", {'question_binary' : _ctx.question.rightAnswer}]),
    ref: "question"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["question__title", {'question__title_activated' : _ctx.question.activated || _ctx.questionValid}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateQuestion($event)))
    }, _toDisplayString(_ctx.question.title), 3),
    (!_ctx.question.rightAnswer && _ctx.question.activated)
      ? (_openBlock(), _createBlock(_component_TheEditor, {
          key: 0,
          class: "editor-content",
          isBold: _ctx.question.type === _ctx.questionTypeEnum.BOLD,
          presetEditorValue: _ctx.question.answer,
          onEditorThrewValue: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateEditorValue($event))),
          onEditorThrewFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('block-active'))),
          onEditorThrewBlur: _cache[3] || (_cache[3] = ($event: any) => {_ctx.$emit('block-inactive'); _ctx.deactivateQuestion($event)})
        }, null, 8, ["isBold", "presetEditorValue"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.question.rightAnswer && _ctx.question.activated)
          ? (_openBlock(), _createBlock(_component_QuestionAnswerVariants, {
              key: 0,
              variants: _ctx.question.answerVariants,
              presetValue: _ctx.question.answer,
              disabled: _ctx.disabled,
              onVariantThrown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateBinaryAnswer($event)))
            }, null, 8, ["variants", "presetValue", "disabled"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 1,
          errorMessage: _ctx.error
        }, null, 8, ["errorMessage"]))
      : _createCommentVNode("", true)
  ], 2))
}