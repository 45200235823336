import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1f288fcd")
const _hoisted_1 = { class: "flex items_center header" }
const _hoisted_2 = { class: "flex items_center justify_between header__content" }
const _hoisted_3 = {
  key: 1,
  class: "cl"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.prevRoute)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button-back",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
          }))
        : (_openBlock(), _createElementBlock("span", _hoisted_3)),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}