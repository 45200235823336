

import {Options, Vue} from "vue-class-component";
import router from "@/router";
import ThePreloader from "@/shared/components/ThePreloader.vue";
import {ResultStepEnum} from "@/schema/result.model";
import ResultsService from "@/core/services/results.service";
import TestApiService from "@/core/services/test-api.service";
import QuestionnaireApiService from "@/core/services/questionnaire-api.service";
import {Watch} from "vue-property-decorator";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import UIService from "@/shared/services/ui.service";

const resultsService = new ResultsService();
const testApiService = new TestApiService();
const questionnaireApiService = new QuestionnaireApiService();
const detectDeviceService = new DetectDeviceService();
const uiService = new UIService();

@Options({
  components: {
    ThePreloader
  }
})
export default class LastStep extends Vue {
  isLoading = true;
  isFinished: boolean | null = null
  errorMessage: null | {title: string, message: string, again: boolean} = null;
  userID!: string;
  pdfDownloaded = false;
  pdfLink = '';
  isIOS!: boolean;

  publish(): void {
    this.isLoading = true;
    this.isFinished = false;
    const isOnline = navigator.onLine;
    if (isOnline) {
      const questionnaireContent = localStorage.getItem('questionnaireContent');
      const questionnaireAnswers = JSON.parse(localStorage.getItem('questionnaireAnswers'));
      questionnaireApiService.publishQuestionnaire(questionnaireContent, questionnaireAnswers).then(() => {
        const testContent = localStorage.getItem('testContent');
        const testAnswers = JSON.parse(localStorage.getItem('testAnswers')).filter(answer => answer.answerHtml.length > 0);
        testApiService.publishTest(testContent, testAnswers).then(() => {
          localStorage.setItem('progress', 'D');
          this.isFinished = true;
          this.isLoading = false;
          this.errorMessage = null;
        }).catch(() => {
          this.isFinished = false;
          this.isLoading = false;
          this.errorMessage = {
            title: 'Ошибка',
            message: 'При отправке задания произошла ошибка. Попробуйте еще раз.',
            again: true
          }
        });
      }).catch(error => {
        this.isFinished = false;
        this.isLoading = false;
        this.errorMessage = {
          title: 'Ошибка',
          message: 'При отправке задания произошла ошибка. Попробуйте еще раз.',
          again: true
        }
      })
    } else {
      this.isFinished = false;
      this.isLoading = false;
      this.errorMessage = {
        title: 'Отсутствует соединение',
        message: 'Отправка тестового задания невозможна из-за отсутствия интернета.',
        again: false
      }
    }
  }

  resetConnectionError(): void {
    this.isFinished = false;
    this.isLoading = false;
    this.errorMessage = null;
  }

  turnBackToTest(): void {
    this.$router.push({
      name: 'Test'
    })
  }

  downloadResults(): void {
    resultsService.getPDF().then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ТестовоеPostuf.pdf`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      this.pdfDownloaded = true;
      this.pdfLink = url;
    })
  }

  getResultId(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let resultId = localStorage.getItem('resultId');
      if (resultId) {
        resultId = resultId.slice(0, -1);
        const dividerIndex = resultId.indexOf(':');
        this.userID = resultId.slice(dividerIndex + 1);
        resolve();
      } else {
        reject();
      }
    })
  }

  created(): void {
    this.getResultId().then(() => {
      this.isIOS = detectDeviceService.detectIsIOS();
      const progress = localStorage.getItem('progress');

      const today = new Date();
      const resetDateFromStorage = JSON.parse(localStorage.getItem('resetDate'));

      if (resetDateFromStorage) {
        const expiredDate = new Date(resetDateFromStorage);
        if (expiredDate <= today) { // Expired
          window.localStorage.clear();
          window.location.href = '/';
        } else { // Not expired
          this.isFinished = progress === 'D';
          this.isLoading = false;
          uiService.removePreloader();
        }
      }
    }).catch(() => {
      window.localStorage.clear();
      window.location.href = '/';
    })
  }
}
