
import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

export default class IconBase extends Vue {
  @Prop({type: String, default: 'box'}) iconName: string
  @Prop({type: [Number, String], default: 14}) width: number
  @Prop({type: [Number, String], default: 14}) height: number
  @Prop({type: String, default: 'transparent'}) fillColor: string
  @Prop({type: String, default: 'transparent'}) strokeColor: string
  @Prop({type: String, default: '0 0 26 26'}) viewBox: string
}
