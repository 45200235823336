
import {Options, Vue} from "vue-class-component";
import TheHeader from "@/shared/components/TheHeader.vue";
import ResultsService from "@/core/services/results.service";
import MobileContentService from "@/mobile/mobile.content.service";
import {Answer, ContentDescription, FullQuestion, QuestionTypeEnum} from "@/schema/content.model";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import UIService from "@/shared/services/ui.service";
import easyScroll from 'easy-scroll';
import ResizeObserver from 'resize-observer-polyfill';
import AnimationService from "@/shared/services/animation.service";
import RestoreService from "@/core/services/restore.service";
import ContentBlock from "@/shared/components/ContentBlock.vue";
import TestService from "@/core/services/test.service";

const resultsService = new ResultsService();
const testService = new TestService();
const contentService = new MobileContentService();
const detectDeviceService = new DetectDeviceService();
const uiService = new UIService();
const animationService = new AnimationService();
const restoreService = new RestoreService();


@Options({
  components: {
    TheHeader,
    ContentBlock
  },
  computed: {
    ableToPublish(): boolean {
      return this.isAbleToPublish();
    }
  }
})
export default class TestIOS extends Vue {
  blocks: Array<Array<ContentDescription | FullQuestion>> = [];
  visibleBlocks: Array<Array<ContentDescription | FullQuestion>> = [];
  answers: Answer[] = [];
  totalLength = 1;
  activeBlockIndex = 0;
  isIpad = false;

  isScrollOver = false;

  timeoutId!: number;

  container!: HTMLElement;
  layout!: HTMLElement;
  content!: HTMLElement;
  testInput!: HTMLInputElement;

  scrollBar!: any;

  deviceHeight!: number;
  breakLinePosition = 0;

  getBlocks(): void {
    this.timeoutId = setTimeout(() => {
      this.blocks = JSON.parse(localStorage.getItem('testBlocks'));
      if (!this.blocks) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.getBlocks();
      } else {
        this.restoreData();
        if (this.answers.length === 0) {
          this.showNextBlock(this.answers.length);
        } else {
          if (this.answers.length === this.visibleBlocks.length && this.answers[this.answers.length - 1].answerHtml.length !== 0) {
            this.showNextBlock(this.answers.length);
          }
        }
        this.totalLength = JSON.parse(localStorage.getItem('testTotalLength'));
        uiService.removePreloader();
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    }, 10);
  }

  restoreData(): void {
    const restoredData = restoreService.restoreData('test');
    this.blocks = restoredData.blocks;
    this.visibleBlocks = restoredData.visibleBlocks;
    this.answers = restoredData.savedAnswers;
    this.$nextTick(() => {
      const domBlocks = document.querySelectorAll('.content-block__wrapper');
      if (this.visibleBlocks[this.visibleBlocks.length - 1] === undefined) {
        this.visibleBlocks.pop();
      }
      domBlocks.forEach((block, index) => {
        if (index + 1 < this.visibleBlocks.length) {
          block.classList.add('blocked');
        }
      });
    })
  }

  activateQuestion(questionData: {questionElement, clientY: number}, index: number): void {
    if (!questionData.questionElement.classList.contains('question_binary')) {
      this.testInput.focus();
    }

    if (this.breakLinePosition === 0) {
      setTimeout(() => {
        this.breakLinePosition = window.visualViewport.height;
      }, 1000);
    }
  }

  saveAnswer(id: number, answer: string = '', answerHtml: string, answerMeta: string = ''): void {
    const answerToSave: Answer = {
      questionId: id,
      answer: answer,
      answerHtml: answerHtml,
      answerMeta: answerMeta
    }
    this.answers = [...testService.addAnswerToList(answerToSave)];
  }

  showNextBlock(index: number): void {
    this.visibleBlocks.push(this.blocks[index]);
    this.riseUpContent();
  }

  increaseVisibleBlocks(validBlock: {index: number, question: FullQuestion}, nextBlock): void {
    this.saveAnswer(validBlock.question.id, '', validBlock.question.answer, '');
    const foundBlock = this.visibleBlocks.find(visibleBlock => JSON.stringify(visibleBlock) === JSON.stringify(nextBlock));
    if (!foundBlock) {
      if (this.visibleBlocks.length < this.blocks.length) {
        this.showNextBlock(validBlock.index + 1);
      }
    }
    if (validBlock.question.type === 'binary') {
      this.riseUpContent();
    }
  }

  decreaseVisibleBlocks(invalidBlock: {index: number, question: {id: number, answer: string}}, index): void {
    const nextBlock = this.visibleBlocks.find(visibleBlock => JSON.stringify(visibleBlock) === JSON.stringify(this.blocks[invalidBlock.index + 1]));
    if (nextBlock) {
      const nextBlockQuestion = nextBlock.find(blockElement => (<FullQuestion>blockElement).answerVariants) as FullQuestion;
      if (nextBlockQuestion && nextBlockQuestion.answer.length === 0 && invalidBlock.index + 2 === this.visibleBlocks.length) {
        nextBlockQuestion.activated = false;
        this.visibleBlocks.pop();
      }
    }
    this.decreaseAnswers(invalidBlock);
  }

  decreaseAnswers(invalidBlock: {index: number, question: {id: number, answer: string}}): void {
    this.blocks[invalidBlock.index].map(innerBlock => {
      if ((<FullQuestion>innerBlock).id === invalidBlock.question.id) {
        (<FullQuestion>innerBlock).answer = invalidBlock.question.answer;
        this.saveAnswer(invalidBlock.question.id, '', invalidBlock.question.answer, '');
      }
    });
  }

  isAbleToPublish(): boolean {
    if (this.answers.length > 0) {
      const filled = (element) => element.answerHtml.length !== 0;
      return this.answers.some(filled);
    }
    return false
  }

  publishTest(): void {
    if (this.answers.length !== 0) {
      const layoutWrapper = this.$refs.layoutWrapper as HTMLElement;
      const layoutContent = layoutWrapper.outerHTML;
      const content = contentService.createContentString(layoutContent);
      localStorage.setItem('testContent', content);

      this.$router.push({
        name: 'Finish'
      })
    }
  }

  initGlobalVariables(): void {
    this.layout = this.$refs.layoutWrapper as HTMLElement;
    this.container = this.$refs.container as HTMLElement;
    this.content = this.$refs.contentWrapper as HTMLElement;
    this.testInput = this.$refs.testInput as HTMLInputElement;
    this.deviceHeight = window.innerHeight;
  }

  increaseHeight(): void {
    const delta = this.layout.offsetHeight - this.content.offsetHeight;
    if (delta < window.innerHeight  / 2) {
      this.layout.style.height = `${this.layout.offsetHeight + delta}px`;
    }
  }


  created() {
    this.getResultId();
    const progress = localStorage.getItem('progress');
    if (!progress) {
      localStorage.setItem('progress', 'T');
    } else if (progress && progress !== 'D') {
      localStorage.setItem('progress', 'T');
    }

    this.getBlocks();
  }

  mounted() {
    this.initGlobalVariables();

    setTimeout(() => {
      this.layout.style.height = this.content.offsetHeight + window.innerHeight + 'px';
    }, 100);

    this.isIpad = detectDeviceService.checkDesktopIPad();
    const resizeObserver = new ResizeObserver((entries, observer) => {
      this.increaseHeight();
      this.riseUpContent();
    });

    if (window.visualViewport !== undefined) {
      window.visualViewport.addEventListener('resize', (e) => {
        this.riseUpContent();
      })
    }

    resizeObserver.observe(this.content);
    this.$nextTick(() => {
      window.scrollTo(0,0);
    });
  }

  getResultId(): void {
    let resultId = localStorage.getItem('resultId');
    if (!resultId) {
      window.localStorage.clear();
      window.location.href = '/';
    }
  }
  riseUpContent(): void {
    if (window.visualViewport.height !== this.deviceHeight) {
      const lastBlock = document.querySelector('.block_last-visible') as HTMLElement;
      const activeBlock = document.querySelector('.block_active') as HTMLElement;
      if (activeBlock && lastBlock) {
        const lastBlockIndex = lastBlock.getAttribute('block-index');
        const activeBlockIndex = activeBlock.getAttribute('block-index');
        const lastBlockTop = lastBlock.getBoundingClientRect().top;
        let additionalHeight = detectDeviceService.is15IOS();
        if (+activeBlockIndex + 1 === +lastBlockIndex) {
          if (lastBlockTop > this.breakLinePosition - lastBlock.offsetHeight - 20 - additionalHeight && lastBlockTop - this.breakLinePosition < lastBlock.offsetHeight * 2) {
            const riseUpValue = lastBlockTop - this.breakLinePosition + lastBlock.offsetHeight;
            if (riseUpValue > 0) {
              const scrollConfig = {
                'scrollableDomEle': window,
                'direction': 'bottom',
                'duration': 200,
                'easingPreset': 'linear',
                'onAnimationCompleteCallback': () => {

                },
                'onRefUpdateCallback': (e) => {
                  if (this.isScrollOver === true) {
                    window.cancelAnimationFrame(e);
                  }
                },
                'scrollAmount': riseUpValue
              }
              easyScroll(scrollConfig);
            }
          }
        }
      }
    }
  }
}
