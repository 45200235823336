import axios from "axios";
import {QuestionnaireAnswer} from "@/schema/questionnaire.model";
import {Answer} from "@/schema/content.model";

export default class QuestionnaireService {

    addAnswerToList(answerToAdd: Answer): Answer[]{
        let answers = JSON.parse(localStorage.getItem('questionnaireAnswers'));
        if (!answers) {
            answers = [];
            answers.push(answerToAdd);
            localStorage.setItem('questionnaireAnswers', JSON.stringify(answers));
        }
        let found = answers.find(answer => answer.questionId === answerToAdd.questionId);
        if (found) {
            const foundIndex = answers.indexOf(found);
            answers[foundIndex] = {...answerToAdd};
        } else {
            answers.push(answerToAdd);
        }
        localStorage.setItem('questionnaireAnswers', JSON.stringify(answers))
        return answers;
    }

    removeAnswer(answers: Answer[]): void {
        localStorage.setItem('questionnaireAnswers', JSON.stringify(answers));
    }
}
