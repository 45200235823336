
import {Options, Vue} from "vue-class-component";
import TheHeader from "@/shared/components/TheHeader.vue";
import ContentBlock from "@/shared/components/ContentBlock.vue";
import TheEditor from "@/shared/components/TheEditor.vue";
import ResultsService from "@/core/services/results.service";
import ContentService from "@/core/services/content.service";
import {Answer, ContentDescription, FullQuestion, QuestionTypeEnum} from "@/schema/content.model";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import TestApiService from "@/core/services/test-api.service";
import TestService from "@/core/services/test.service";
import ResizeObserver from "resize-observer-polyfill";
import UIService from "@/shared/services/ui.service";
import AnimationService from "@/shared/services/animation.service";
import RestoreService from "@/core/services/restore.service";
import Scrollbar from "smooth-scrollbar";


const resultsService = new ResultsService();
const testService = new TestService();
const testApiService = new TestApiService();
const contentService = new ContentService();
const detectDeviceService = new DetectDeviceService();
const uiService = new UIService();
const animationService = new AnimationService();
const restoreService = new RestoreService();


@Options({
  components: {
    TheHeader,
    ContentBlock,
    TheEditor,
  },
  computed: {
    ableToPublish(): boolean {
      return this.isAbleToPublish();
    }
  }
})
export default class Test extends Vue {
  blocks: Array<Array<ContentDescription | FullQuestion>> = [];
  visibleBlocks: Array<Array<ContentDescription | FullQuestion>> = [];
  answers: Answer[] = [];
  totalLength = 1;

  isScrollOver = false;

  isMobile = false;
  isIOS = false;

  timeoutId!: number;

  container!: HTMLElement;
  layout!: HTMLElement;
  content!: HTMLElement;
  testInput!: HTMLInputElement;

  scrollBar!: any;

  deviceHeight!: number;
  breakLinePosition!: number;

  getBlocks(): void {
    this.timeoutId = setTimeout(() => {
      this.blocks = JSON.parse(localStorage.getItem('testBlocks'));
      if (!this.blocks) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.getBlocks();
      } else {
        this.restoreData();
        if (this.answers.length === 0) {
          this.showNextBlock(this.answers.length);
        } else {
          if (this.answers.length === this.visibleBlocks.length && this.answers[this.answers.length - 1].answerHtml.length !== 0) {
            this.showNextBlock(this.answers.length);
          }
        }
        this.totalLength = JSON.parse(localStorage.getItem('testTotalLength'));
        uiService.removePreloader();
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    }, 10);
  }

  restoreData(): void {
    const restoredData = restoreService.restoreData('test');
    this.blocks = restoredData.blocks;
    this.visibleBlocks = restoredData.visibleBlocks;
    this.answers = restoredData.savedAnswers;
    this.$nextTick(() => {
      const domBlocks = document.querySelectorAll('.content-block__wrapper');
      if (this.visibleBlocks[this.visibleBlocks.length - 1] === undefined) {
        this.visibleBlocks.pop();
      }
      domBlocks.forEach((block, index) => {
        if (index + 1 < this.visibleBlocks.length) {
          block.classList.add('blocked');
        }
      });
    })
  }

  activateQuestion(questionData: {questionElement, clientY: number}, index: number): void {
    const browser = detectDeviceService.detectBrowser();
    const scrollValue = animationService.detectScrollDownValue(browser, questionData.questionElement, questionData.clientY);
    const scrollValueTotal = (this.scrollBar.offset.y + scrollValue);
    this.scrollBar.scrollTo(0, scrollValueTotal, 500);
  }

  saveAnswer(id: number, answer: string = '', answerHtml: string, answerMeta: string = ''): void {
    const answerToSave: Answer = {
      questionId: id,
      answer: answer,
      answerHtml: answerHtml,
      answerMeta: answerMeta
    }
    this.answers = [...testService.addAnswerToList(answerToSave)];
  }

  showNextBlock(index: number): void {
    this.visibleBlocks.push(this.blocks[index]);
    this.$nextTick(() => {
      this.riseUpContent();
    })
  }

  increaseVisibleBlocks(validBlock: {index: number, question: FullQuestion}, nextBlock): void {
    this.saveAnswer(validBlock.question.id, '', validBlock.question.answer, '');
    const foundBlock = this.visibleBlocks.find(visibleBlock => JSON.stringify(visibleBlock) === JSON.stringify(nextBlock));
    if (!foundBlock) {
      if (this.visibleBlocks.length < this.blocks.length) {
        this.showNextBlock(validBlock.index + 1);
      }
    }
    if (validBlock.question.type === 'binary') {
      this.riseUpContent();
    }
  }

  decreaseVisibleBlocks(invalidBlock: {index: number, question: {id: number, answer: string}}): void {
    const nextBlock = this.visibleBlocks.find(visibleBlock => JSON.stringify(visibleBlock) === JSON.stringify(this.blocks[invalidBlock.index + 1]));
    if (nextBlock) {
      const nextBlockQuestion = nextBlock.find(blockElement => (<FullQuestion>blockElement).answerVariants) as FullQuestion;
      if (nextBlockQuestion && nextBlockQuestion.answer.length === 0 && invalidBlock.index + 2 === this.visibleBlocks.length) {
        nextBlockQuestion.activated = false;
        this.visibleBlocks.pop();
      }
    }
    this.decreaseAnswers(invalidBlock);
  }

  decreaseAnswers(invalidBlock: {index: number, question: {id: number, answer: string}}): void {
    this.blocks[invalidBlock.index].map(innerBlock => {
      if ((<FullQuestion>innerBlock).id === invalidBlock.question.id) {
        (<FullQuestion>innerBlock).answer = invalidBlock.question.answer;
        this.saveAnswer(invalidBlock.question.id, '', invalidBlock.question.answer, '');
      }
    });
  }

  isAbleToPublish(): boolean {
    if (this.answers.length > 0) {
      const filled = (element) => element.answerHtml.length !== 0;
      return this.answers.some(filled);
    }
    return false
  }

  publishTest(): void {
    if (this.answers.length !== 0) {
      const layoutWrapper = this.$refs.layoutWrapper as HTMLElement;
      const layoutContent = layoutWrapper.outerHTML;
      const content = contentService.createContentString(layoutContent);
      localStorage.setItem('testContent', content);

      this.$router.push({
        name: 'Finish'
      })
    }
  }

  initGlobalVariables(): void {
    this.isMobile = detectDeviceService.detectIsMobile();
    this.isIOS = detectDeviceService.detectIsIOS();
    this.layout = this.$refs.layoutWrapper as HTMLElement;
    this.container = this.$refs.container as HTMLElement;
    this.content = this.$refs.contentWrapper as HTMLElement;
    this.testInput = this.$refs.testInput as HTMLInputElement;
    this.deviceHeight = window.innerHeight;
  }

  created() {
    this.getResultId();
    const progress = localStorage.getItem('progress');
    if (!progress) {
      localStorage.setItem('progress', 'T');
    } else if (progress && progress !== 'D') {
      localStorage.setItem('progress', 'T');
    }

    this.getBlocks();
  }

  getResultId(): void {
    let resultId = localStorage.getItem('resultId');
    if (!resultId) {
      window.localStorage.clear();
      window.location.href = '/';
    }
  }

  getDampingValue(): number {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Macintosh')) {
      return 1;
    }
    return 0.08
  }

  mounted() {
    this.initGlobalVariables();
    this.increaseHeight();

    this.scrollBar = Scrollbar.init(this.container, {
      damping: this.getDampingValue(),
      thumbMinSize: 0,
      renderByPixels: true,
      alwaysShowTracks: false,
      continuousScrolling: true
    });

    const resizeObserver = new ResizeObserver((entries, observer) => {
      this.increaseHeight();
      this.$nextTick(() => {
        this.riseUpContent();
      })
    });
    resizeObserver.observe(this.content);
  }

  increaseHeight(): void {
    const delta = this.layout.offsetHeight - this.content.offsetHeight;
    if (delta < window.innerHeight) {
      const valueToIncrease = window.innerHeight - delta;
      this.layout.style.height = `${this.layout.offsetHeight + valueToIncrease}px`;
    }
  }

  riseUpContent(): void {
    this.breakLinePosition = window.innerHeight - 100;
    const lastBlock = document.querySelector('.block_last-visible') as HTMLElement ;
    const activeBlock = document.querySelector('.block_active') as HTMLElement;
    if (activeBlock && lastBlock) {
      const lastBlockIndex = lastBlock.getAttribute('block-index');
      const activeBlockIndex = activeBlock.getAttribute('block-index');
      const lastBlockTop = lastBlock.getBoundingClientRect().top;
      if (+activeBlockIndex + 1 === +lastBlockIndex) {
        if (lastBlockTop > this.breakLinePosition - lastBlock.offsetHeight - 20 && lastBlockTop - this.breakLinePosition < lastBlock.offsetHeight * 2) {
          const riseUpValue = lastBlockTop - this.breakLinePosition + lastBlock.offsetHeight;
          const scrollValueTotal = this.scrollBar.offset.y + riseUpValue + 20;
          this.scrollBar.scrollTo(0, scrollValueTotal, 700);
        }
      }
    }
  }

}
