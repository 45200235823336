import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b63d484e")
const _hoisted_1 = {
  class: "container",
  ref: "container"
}
const _hoisted_2 = {
  class: "test-input",
  type: "text",
  ref: "testInput",
  autocomplete: "on",
  autocapitalize: "on",
  spellcheck: "true"
}
const _hoisted_3 = {
  class: "layout__wrapper",
  ref: "layoutWrapper"
}
const _hoisted_4 = {
  class: "content__wrapper",
  ref: "contentWrapper"
}
_popScopeId()

export function render(_ctx: any,_cache: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_TheHeader, {
          key: 0,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass(["header__next-button", {'header__next-button_disabled' : !_ctx.ableToPublish}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.publishQuestionnaire()))
            }, "Далее", 2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", _hoisted_2, null, 512),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["content-block__wrapper", {'content-block__wrapper_last' : index + 1 === _ctx.visibleBlocks.length}])
            }, [
              _createVNode(_component_ContentBlock, {
                block: block,
                index: index,
                totalLength: _ctx.totalLength,
                visibleBlocksLength: _ctx.visibleBlocks.length,
                onQuestionActivated: ($event: any) => (_ctx.activateQuestion($event, index)),
                onContentBlockValid: ($event: any) => (_ctx.increaseVisibleBlocks($event, _ctx.blocks[index + 1])),
                onContentBlockInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decreaseVisibleBlocks($event)))
              }, null, 8, ["block", "index", "totalLength", "visibleBlocksLength", "onQuestionActivated", "onContentBlockValid"])
            ], 2))
          }), 256))
        ], 512)
      ], 512)
    ], 512)
  ], 64))
}