import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-25177fd0")
const _hoisted_1 = { class: "variants" }
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variants, (button, buttonIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["variant-button", {'variant-button_active' : button.value === _ctx.activeValue, 'variant-button_first' : buttonIndex === 0}]),
        onClick: ($event: any) => (_ctx.setBinaryAnswer(button.value, $event))
      }, _toDisplayString(button.title), 11, _hoisted_2))
    }), 256))
  ]))
}