import axios from "axios";
import {QuestionnaireAnswer} from "@/schema/questionnaire.model";
import {Answer} from "@/schema/content.model";

export default class TestApiService {
    downloadBlocks(): Promise<any> {
        return axios.get(`/test/blocks`);
    }
    publishTest(content: string, answers: Answer[]): Promise<any> {
        return axios.post(`/test/done`, {content: content, answers: answers});
    }
    saveAnswersToStorage(answers: Answer[]): void {
        localStorage.setItem("testAnswers", JSON.stringify(answers));
    }
}
