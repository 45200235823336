
import {Options, Vue} from "vue-class-component";
import TheHeader from "@/shared/components/TheHeader.vue";
import MobileEditor from "@/mobile/MobileEditor.vue";
import ResultsService from "@/core/services/results.service";
import ThePreloader from "@/shared/components/ThePreloader.vue";
import MobileContentService from "@/mobile/mobile.content.service";
import {Answer, ContentDescription, FullQuestion, QuestionTypeEnum} from "@/schema/content.model";
import DetectDeviceService from "@/core/middlewares/detect-device.middleware";
import UIService from "@/shared/services/ui.service";
import easyScroll from 'easy-scroll';
import ResizeObserver from 'resize-observer-polyfill';
import TestService from "@/core/services/test.service";

const resultsService = new ResultsService();
const testService = new TestService();
const contentService = new MobileContentService();
const detectDeviceService = new DetectDeviceService();
const uiService = new UIService();


@Options({
  components: {
    TheHeader,
    MobileEditor,
    ThePreloader
  },
  computed: {
    ableToPublish(): boolean {
      return this.isAbleToPublish();
    }
  }
})
export default class TestAndroid extends Vue {
  blocks: Array<Array<ContentDescription | FullQuestion>> = [];
  visibleBlocks: Array<Array<ContentDescription | FullQuestion>> = [];
  answers: Answer[] = [];
  totalLength = 1;

  isScrollOver = false;

  timeoutId!: number;

  container!: HTMLElement;
  layout!: HTMLElement;
  content!: HTMLElement;
  testInput!: HTMLInputElement;

  deviceHeight!: number;
  activeIndex!: number;

  nextBlock: any = null;

  isFooterButtonVisible = false;

  getBlocks(): void {
    this.timeoutId = setTimeout(() => {
      this.blocks = JSON.parse(localStorage.getItem('testBlocks'));
      if (!this.blocks) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.getBlocks();
      } else {
        this.restoreData();
        if (this.answers.length === 0) {
          this.showNextBlock(0);
        }
        this.totalLength = JSON.parse(localStorage.getItem('testTotalLength'));
        uiService.removePreloader();
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        if (this.totalLength === this.visibleBlocks.length) {
          this.isFooterButtonVisible = true;
        }
      }
    }, 10);
  }

  restoreData(): void {
    const savedAnswers = JSON.parse(localStorage.getItem('testAnswers'));
    if (savedAnswers && savedAnswers.length > 0) {
      this.answers = savedAnswers;
      this.blocks.forEach((block, index) => {
        block.forEach(blockElement => {
          this.answers.forEach(answer => {
            if (answer.questionId === (<FullQuestion>blockElement).id) {
              this.visibleBlocks.push(block);
              if ((<FullQuestion>blockElement).type !== QuestionTypeEnum.BINARY) {
                const isEmpty = this.checkIsAnswerEmpty(answer.answerHtml);
                (<FullQuestion>blockElement).activated = true;
                (<FullQuestion>blockElement).answer = answer.answerHtml;
                const nextBlock = this.blocks[index + 1];
                if (nextBlock) {
                  const questionInNextBlock = nextBlock.find(element => (<FullQuestion>element).id) as FullQuestion;
                  if (questionInNextBlock) {
                    const isQuestionIsAlreadyVisible = this.answers.find(answer => answer.questionId === questionInNextBlock.id);
                    if (!isQuestionIsAlreadyVisible) {
                      this.visibleBlocks.push(nextBlock);
                    }
                  }
                }
              } else {
                if (answer.answerHtml.length > 0) {
                  (<FullQuestion>blockElement).activated = true;
                  (<FullQuestion>blockElement).answer = answer.answerHtml;
                  const nextBlock = this.blocks[index + 1];
                  if (nextBlock) {
                    const questionInNextBlock = nextBlock.find(element => (<FullQuestion>element).id) as FullQuestion;
                    if (questionInNextBlock) {
                      const isQuestionIsAlreadyVisible = this.answers.find(answer => answer.questionId === questionInNextBlock.id);
                      if (!isQuestionIsAlreadyVisible) {
                        this.visibleBlocks.push(nextBlock);
                      }
                    }
                  }
                }
              }
            }
          })
        })
      });
    }
  }

  activateQuestion(visibleBlock: Array<ContentDescription | FullQuestion>, questionId: number, event: MouseEvent, index): void {
    this.isScrollOver = false;
    this.activeIndex = index;

    let scrollConfig = {};

    const browser = detectDeviceService.detectBrowser();

    visibleBlock.forEach(block => {
      if ((<FullQuestion>block).id && (<FullQuestion>block).id === questionId) {

        if ((<FullQuestion>block).activated === false) {
          this.nextBlock = this.blocks[index + 1];

          if ((<FullQuestion>block).type !== 'binary') {
            this.testInput.focus();
          }

          (<FullQuestion>block).activated = true;

          const question = event.target as HTMLElement;
          const questionHeight = question.offsetHeight;
          const rect = question.getBoundingClientRect();
          const A = event.clientY - rect.top;
          const B = questionHeight - A;

          let additional = 0;

          if (browser !== 'Chrome' && browser !== 'Opera') {
            additional += 2;
          }
          if (browser === 'Opera') {
            additional += 1;
          }

          const scrollValue = B + 20 + additional;


          scrollConfig = {
            'scrollableDomEle': this.container,
            'direction': 'bottom',
            'duration': 200,
            'easingPreset': 'linear',
            'onAnimationCompleteCallback': () => {

            },
            'onRefUpdateCallback': (e) => {
              if (this.isScrollOver === true) {
                window.cancelAnimationFrame(e);
              }
            },
            'scrollAmount': scrollValue
          }
          easyScroll(scrollConfig);
        } else return;
      }
    })
  }

  saveAnswer(id: number, answer: string = '', answerHtml: string, answerMeta: string = ''): void {
    const answerToSave: Answer = {
      questionId: id,
      answer: answer,
      answerHtml: answerHtml,
      answerMeta: answerMeta
    }
    this.answers = [...testService.addAnswerToList(answerToSave)];
  }

  showNextBlock(index: number): void {
    this.visibleBlocks.push(this.blocks[index]);
  }

  validateEditorValue(visibleBlock: Array<ContentDescription | FullQuestion>, value: string, questionId: number, index: number): void {
    const isEmpty = this.checkIsAnswerEmpty(value);
    const nextBlock = this.blocks[index + 1];
    const isNextBlockAlreadyExist = this.visibleBlocks.find(block => JSON.stringify(block) === JSON.stringify(nextBlock));

    if (this.activeIndex !== index) {
      this.activeIndex = index;
    }

    if (!isEmpty && !isNextBlockAlreadyExist) {
      if (this.totalLength !== this.visibleBlocks.length) {
        this.nextBlock = null;
        this.showNextBlock(index + 1);
      } else {
        this.isFooterButtonVisible = true;
      }
    } else if (isEmpty && isNextBlockAlreadyExist) {
      this.nextBlock = this.blocks[index + 1];
      const questionInBlock = isNextBlockAlreadyExist.find(block => block.hasOwnProperty('activated')) as FullQuestion;
      if (!questionInBlock.activated) {
        this.removeLastVisibleBlock();
      } else if (questionInBlock.activated && questionInBlock.type === 'binary' && questionInBlock.answer === '') {
        questionInBlock.activated = false;
        this.removeLastVisibleBlock();
      }
    }

    visibleBlock.forEach(block => {
      if ((<FullQuestion>block).id && (<FullQuestion>block).id === questionId) {
        (<FullQuestion>block).answer = value;
        if (isEmpty) {
          this.saveAnswer(questionId, '', '', '');
        } else {
          this.saveAnswer(questionId, '', value, '');
        }
      }
    });
  }

  setAnswerVariant(visibleBlock: Array<ContentDescription | FullQuestion>, value: string, questionId: number, index: number): void {
    this.isScrollOver = false;
    this.activeIndex = index;

    visibleBlock.forEach(block => {
      if ((<FullQuestion>block).id && (<FullQuestion>block).id === questionId) {
        (<FullQuestion>block).answer = value;

        const nextBlock = this.blocks[index + 1];
        const isNextBlockAlreadyExist = this.visibleBlocks.find(block => JSON.stringify(block) === JSON.stringify(nextBlock));

        if ((<FullQuestion>block).rightAnswer === value && !isNextBlockAlreadyExist) {
          this.showNextBlock(index + 1);
          this.saveAnswer(questionId, '', value, '');
          this.$nextTick(() => {
            if (this.totalLength === this.visibleBlocks.length) {
              this.isFooterButtonVisible = true;
            }

            const scrollConfig = {
              'scrollableDomEle': this.container,
              'direction': 'bottom',
              'duration': 200,
              'easingPreset': 'linear',
              'onAnimationCompleteCallback': () => {

              },
              'onRefUpdateCallback': (e) => {
                if (this.isScrollOver === true) {
                  window.cancelAnimationFrame(e);
                }
              },
            }
            easyScroll(scrollConfig);
          });
        } else if ((<FullQuestion>block).rightAnswer !== value && isNextBlockAlreadyExist) {
          const questionInBlock = isNextBlockAlreadyExist.find(block => block.hasOwnProperty('activated')) as FullQuestion;
          if (!questionInBlock.activated) {
            this.visibleBlocks.pop();
          }
        }
      }
    });
  }

  isVariantButtonDisabled(index: number, answer: string, rightAnswer: string): boolean {
    const nextBlock = this.blocks[index + 1];
    const isNextBlockAlreadyExist = this.visibleBlocks.find(block => JSON.stringify(block) === JSON.stringify(nextBlock));
    if (isNextBlockAlreadyExist) {
      const questionInBlock = isNextBlockAlreadyExist.find(block => block.hasOwnProperty('activated')) as FullQuestion;
      return questionInBlock.activated && answer === rightAnswer;
    }
    return false;
  }

  checkIsAnswerEmpty(value: string): boolean {
    const blockNode = document.createElement('html');
    blockNode.innerHTML = value;
    const valueNodes = Array.from(blockNode.getElementsByTagName('*')).slice(2);
    return valueNodes.every(this.isNodeEmpty);
  }

  isNodeEmpty(element) {
    return element.innerText.length === 0;
  }

  removeLastVisibleBlock(): void {
    this.visibleBlocks.pop();
  }

  blurEditor(visibleBlock: Array<ContentDescription | FullQuestion>, value: string, questionId: number, index: number): void {
    this.isScrollOver = false;
    this.activeIndex = undefined;
    const isEmpty = this.checkIsAnswerEmpty(value);

    visibleBlock.forEach(block => {
      if ((<FullQuestion>block).id && (<FullQuestion>block).id === questionId) {
        (<FullQuestion>block).answer = value;
        if (isEmpty) {
          this.saveAnswer(questionId, '', '', '');
        } else {
          this.saveAnswer(questionId, '', value, '');
        }

        const found = this.visibleBlocks[index]
            .find(blockElement => typeof (<FullQuestion>blockElement).answer === 'string') as FullQuestion;

        const isTheLast = this.visibleBlocks.length === index + 1;
        if (isTheLast && found && isEmpty) {
          (<FullQuestion>block).activated = false;
          this.isScrollOver = false;
        }
      }
    })
  }

  isAbleToPublish(): boolean {
    let isEmptyAnswerExist;
    if (this.answers.length === 1) {
      this.answers.forEach(answer => {
        const answerContainsTag = answer.answerHtml.indexOf('<');
        if (answerContainsTag !== -1) {
          const isAnswerEmpty = this.checkIsAnswerEmpty(answer.answerHtml);
          if (isAnswerEmpty === true) {
            isEmptyAnswerExist = true;
            return;
          }
        }
        if (isEmptyAnswerExist !== true) {
          isEmptyAnswerExist = false;
        }
      })
      if (isEmptyAnswerExist) {
        return false;
      }
    }
    return this.answers.length !== 0;
  }

  publishTest(): void {
    if (this.answers.length !== 0) {
      const layoutWrapper = this.$refs.layoutWrapper as HTMLElement;
      const layoutContent = layoutWrapper.outerHTML;
      const content = contentService.createContentString(layoutContent);
      localStorage.setItem('testContent', content);

      this.$router.push({
        name: 'Finish'
      })
    }
  }

  preventInput(event: Event): void {
    event.preventDefault();
  }

  created() {
    this.getResultId();
    document.body.removeAttribute('style');
    const progress = localStorage.getItem('progress');
    if (!progress) {
      localStorage.setItem('progress', 'T');
    } else if (progress && progress !== 'D') {
      localStorage.setItem('progress', 'T');
    }

    this.getBlocks();
  }

  getResultId(): void {
    let resultId = localStorage.getItem('resultId');
    if (!resultId) {
      window.localStorage.clear();
      window.location.href = '/';
    }
  }

  riseUpContent(): void {
    this.isScrollOver = false;
    const lastQuestion = document.querySelector('.question_last') as HTMLElement ;

    if (lastQuestion) {
      const lastQuestionIndex = lastQuestion.getAttribute('question-index');
      if (Number(lastQuestionIndex) - this.activeIndex === 1) {
        const rect = lastQuestion.getBoundingClientRect();
        const questionHeight = lastQuestion.offsetHeight;
        if (rect.top > window.innerHeight - questionHeight - 20 && rect.top < window.innerHeight + lastQuestion.parentElement.parentElement.offsetHeight * 1.5) {
          const scrollConfig = {
            'scrollableDomEle': this.container,
            'direction': 'bottom',
            'duration': 200,
            'easingPreset': 'linear',
            'onAnimationCompleteCallback': () => {

            },
            'onRefUpdateCallback': (e) => {
              if (this.isScrollOver === true) {
                window.cancelAnimationFrame(e);
              }
            },
            'scrollAmount': rect.top - window.innerHeight + questionHeight + 20
          }
          easyScroll(scrollConfig);
        }
      }
    } else {
      if (this.totalLength - this.activeIndex === 1 || this.visibleBlocks.length - this.activeIndex === 1) {
        const scrollConfig = {
          'scrollableDomEle': this.container,
          'direction': 'bottom',
          'duration': 200,
          'easingPreset': 'linear',
          'onAnimationCompleteCallback': () => {

          },
          'onRefUpdateCallback': (e) => {
            if (this.isScrollOver === true) {
              window.cancelAnimationFrame(e);
            }
          },
        }
        easyScroll(scrollConfig);
      }
    }
  }

  mounted() {
    this.layout = this.$refs.layoutWrapper as HTMLElement;
    this.container = this.$refs.container as HTMLElement;
    this.content = this.$refs.contentWrapper as HTMLElement;
    this.testInput = this.$refs.testInput as HTMLInputElement;
    this.deviceHeight = window.innerHeight;

    if (window.visualViewport !== undefined) {
      window.visualViewport.addEventListener('resize', (e) => {
        this.riseUpContent();
      })
    }

    const resizeObserver = new ResizeObserver((entries, observer) => {
      this.riseUpContent();
    })

    resizeObserver.observe(this.content);
  }
}
