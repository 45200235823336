import { withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3f4ba656")
const _hoisted_1 = { class: "flex flex_column editor__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "link-editor"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconTextBold = _resolveComponent("IconTextBold")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_IconTextItalic = _resolveComponent("IconTextItalic")!
  const _component_IconLink = _resolveComponent("IconLink")!
  const _component_IconHeading2 = _resolveComponent("IconHeading2")!
  const _component_IconHeading3 = _resolveComponent("IconHeading3")!
  const _component_bubble_menu = _resolveComponent("bubble-menu")!
  const _component_editor_content = _resolveComponent("editor-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isBubbleVisible)
      ? (_openBlock(), _createBlock(_component_bubble_menu, {
          key: 0,
          class: _normalizeClass(["bubble-menu", {'bubble-menu_android': _ctx.isMobile === true && _ctx.isIOS === false, 'bubble-menu_ios' : _ctx.isIOS === true}]),
          editor: _ctx.editor
        }, {
          default: _withCtx(() => [
            (_ctx.isLinkEditorVisible && _ctx.editor)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("input", {
                    type: "text",
                    class: "link-editor__input",
                    placeholder: "Paste or type a link...",
                    ref: "linkEditorInput",
                    onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => {_ctx.setLink($event); _ctx.focusEditor()}, ["enter"]))
                  }, null, 544),
                  _createElementVNode("button", {
                    class: "link-editor__button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLinkEditorVisible = false))
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: _normalizeClass(["bubble-menu__button", { 'active': _ctx.editor.isActive('bold') }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.editor.chain().focus().toggleBold().run(); _ctx.focusEditor()})
            }, [
              _createVNode(_component_IconBase, {
                "icon-name": "Выделить жирным",
                width: _ctx.iconSize,
                height: _ctx.iconSize,
                fillColor: _ctx.editor.isActive('bold') ? 'var(--text_primary)' : 'var(--text_inverse)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconTextBold)
                ]),
                _: 1
              }, 8, ["width", "height", "fillColor"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["bubble-menu__button", { 'active': _ctx.editor.isActive('italic') }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.editor.chain().focus().toggleItalic().run(); _ctx.focusEditor()})
            }, [
              _createVNode(_component_IconBase, {
                "icon-name": "Выделить курсивом",
                width: _ctx.iconSize,
                height: _ctx.iconSize,
                fillColor: _ctx.editor.isActive('italic') ? 'var(--text_primary)' : 'var(--text_inverse)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconTextItalic)
                ]),
                _: 1
              }, 8, ["width", "height", "fillColor"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["bubble-menu__button", { 'active': _ctx.editor.isActive('link') }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.switchLinkPopup(); _ctx.focusEditor()})
            }, [
              _createVNode(_component_IconBase, {
                "icon-name": "Прикрепить ссылку",
                width: _ctx.iconSize,
                height: _ctx.iconSize,
                fillColor: _ctx.editor.isActive('link') ? 'var(--text_primary)' : 'var(--text_inverse)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconLink)
                ]),
                _: 1
              }, 8, ["width", "height", "fillColor"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["bubble-menu__button", { 'active': _ctx.editor.isActive('heading', {level: 2}) }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => {_ctx.editor.chain().focus().toggleHeading({level: 2}).run(); _ctx.focusEditor()})
            }, [
              _createVNode(_component_IconBase, {
                "icon-name": "Сделать заголовком H2",
                width: _ctx.iconSize,
                height: _ctx.iconSize,
                fillColor: _ctx.editor.isActive('heading', {level: 2}) ? 'var(--text_primary)' : 'var(--text_inverse)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconHeading2)
                ]),
                _: 1
              }, 8, ["width", "height", "fillColor"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["bubble-menu__button", { 'active': _ctx.editor.isActive('heading', {level: 3}) }]),
              onClick: _cache[6] || (_cache[6] = ($event: any) => {_ctx.editor.chain().focus().toggleHeading({level: 3}).run(); _ctx.focusEditor()})
            }, [
              _createVNode(_component_IconBase, {
                "icon-name": "Сделать заголовком H3",
                width: _ctx.iconSize,
                height: _ctx.iconSize,
                fillColor: _ctx.editor.isActive('heading', {level: 3}) ? 'var(--text_primary)' : 'var(--text_inverse)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconHeading3)
                ]),
                _: 1
              }, 8, ["width", "height", "fillColor"])
            ], 2)
          ]),
          _: 1
        }, 8, ["editor", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_editor_content, {
      tabindex: "1",
      editor: _ctx.editor,
      ref: "editorContent",
      class: "editor-content"
    }, null, 8, ["editor"])
  ]))
}