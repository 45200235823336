import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toDisplayString as _toDisplayString, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7f897eab")
const _hoisted_1 = {
  class: "container",
  ref: "container"
}
const _hoisted_2 = {
  class: "layout__wrapper",
  ref: "layoutWrapper"
}
const _hoisted_3 = {
  class: "content__wrapper",
  ref: "contentWrapper"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["question-index", "id"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "editor-content variants"
}
const _hoisted_8 = ["disabled", "onClick"]
const _hoisted_9 = {
  key: 0,
  class: "error-message"
}
const _hoisted_10 = {
  key: 0,
  style: {"opacity":"0"}
}
const _hoisted_11 = { class: "next-block" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "question" }
const _hoisted_14 = { class: "question__title" }
const _hoisted_15 = {
  class: "test-input",
  type: "email",
  ref: "testInput"
}
_popScopeId()

export function render(_ctx: any,_cache: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_MobileEditor = _resolveComponent("MobileEditor")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_TheHeader, { class: "header" }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  class: _normalizeClass(["header__next-button", {'header__next-button_enabled' : _ctx.answers.length === _ctx.totalLength && _ctx.ableToPublish}]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.publishQuestionnaire()))
                }, "Далее", 2)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleBlocks, (visibleBlock, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["block", {'block_visible' : index + 1 <= _ctx.visibleBlocks.length}])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleBlock, (visibleBlockContent) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    _createVNode(_Transition, { name: "fade" }, {
                      default: _withCtx(() => [
                        (visibleBlockContent.content)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "text-block",
                              innerHTML: visibleBlockContent.content
                            }, null, 8, _hoisted_4))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_Transition, { name: "fade" }, {
                      default: _withCtx(() => [
                        (visibleBlockContent.answerVariants)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["question", {'question_bold' : visibleBlockContent.type === 'bold',
                     'question_last' : index + 1 === _ctx.visibleBlocks.length && visibleBlockContent.activated === false}]),
                              "question-index": index,
                              id: visibleBlockContent.id
                            }, [
                              _createElementVNode("span", {
                                class: _normalizeClass(["question__title", {'question__title_activated' : visibleBlockContent.activated === true}]),
                                onClick: ($event: any) => (_ctx.activateQuestion(visibleBlock, visibleBlockContent.id, $event, index))
                              }, _toDisplayString(visibleBlockContent?.title), 11, _hoisted_6),
                              (visibleBlockContent.errorMessage.length === 0 && visibleBlockContent.activated === true)
                                ? (_openBlock(), _createBlock(_component_MobileEditor, {
                                    key: 0,
                                    class: _normalizeClass(["editor-content", {'editor-content_activated': visibleBlockContent.answer.length > 0}]),
                                    isBold: visibleBlockContent.type === 'bold',
                                    presetEditorValue: visibleBlockContent.answer,
                                    onEditorThrewValue: ($event: any) => (_ctx.validateEditorValue(visibleBlock, $event, visibleBlockContent.id, index)),
                                    onEditorThrewBlur: ($event: any) => (_ctx.blurEditor(visibleBlock, $event, visibleBlockContent.id, index))
                                  }, null, 8, ["isBold", "presetEditorValue", "class", "onEditorThrewValue", "onEditorThrewBlur"]))
                                : _createCommentVNode("", true),
                              _createVNode(_Transition, { name: "buttons-fade" }, {
                                default: _withCtx(() => [
                                  (visibleBlockContent.errorMessage.length > 0 && visibleBlockContent.activated === true)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleBlockContent.answerVariants, (button, buttonIndex) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            class: _normalizeClass(["variant-button", {'variant-button_active' : visibleBlockContent.answer === button.value,
                            'variant-button_disabled' : _ctx.isVariantButtonDisabled(index, visibleBlockContent.answer, visibleBlockContent.rightAnswer), 'variant-button_first' : buttonIndex === 0}]),
                                            disabled: _ctx.isVariantButtonDisabled(index, visibleBlockContent.answer, visibleBlockContent.rightAnswer),
                                            onClick: ($event: any) => (_ctx.setAnswerVariant(visibleBlock, button.value, visibleBlockContent.id, index))
                                          }, _toDisplayString(button?.title), 11, _hoisted_8))
                                        }), 256)),
                                        (visibleBlockContent.answer.length > 0 && visibleBlockContent.answer !== visibleBlockContent.rightAnswer)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(visibleBlockContent.errorMessage), 1))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ], 10, _hoisted_5))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 256))
              ], 2))
            }), 256)),
            (_ctx.nextBlock !== null && _ctx.answers.length < _ctx.totalLength)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nextBlock, (visibleBlockContent) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (visibleBlockContent.content)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "text-block",
                            innerHTML: visibleBlockContent.content
                          }, null, 8, _hoisted_12))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(visibleBlockContent.title), 1)
                      ])
                    ]))
                  }), 256))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("input", _hoisted_15, null, 512),
            (_ctx.answers.length === _ctx.totalLength && _ctx.ableToPublish)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "header__next-button header__next-button_bottom",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.publishQuestionnaire()))
                }, "Далее"))
              : _createCommentVNode("", true)
          ], 512)
        ], 512)
      ], 512)
    ]),
    _: 1
  }))
}