import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2a6fbe8c")
const _hoisted_1 = {
  type: "text",
  class: "test-input",
  ref: "testInput",
  autocomplete: "on",
  autocapitalize: "on",
  spellcheck: "true"
}
const _hoisted_2 = {
  class: "layout__wrapper",
  ref: "layoutWrapper"
}
const _hoisted_3 = {
  class: "content__wrapper",
  ref: "contentWrapper"
}
_popScopeId()

export function render(_ctx: any,_cache: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", {'container_ipad' : _ctx.isIpad}]),
    ref: "container"
  }, [
    _createElementVNode("input", _hoisted_1, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TheHeader, {
          class: "header",
          prevRoute: {name: 'Questionnaire'}
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass(["header__next-button", {'header__next-button_enabled' : _ctx.ableToPublish}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.publishTest()))
            }, "Далее", 2)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["content-block__wrapper", {'content-block__wrapper_last' : index + 1 === _ctx.visibleBlocks.length,
             'content-block__wrapper_hidden' : index + 1 > _ctx.visibleBlocks.length}])
          }, [
            _createVNode(_component_ContentBlock, {
              block: block,
              index: index,
              totalLength: _ctx.totalLength,
              visibleBlocksLength: _ctx.visibleBlocks.length,
              onQuestionActivated: ($event: any) => (_ctx.activateQuestion($event, index)),
              onContentBlockValid: ($event: any) => (_ctx.increaseVisibleBlocks($event, _ctx.blocks[index + 1])),
              onContentBlockInvalid: ($event: any) => (_ctx.decreaseVisibleBlocks($event, index))
            }, null, 8, ["block", "index", "totalLength", "visibleBlocksLength", "onQuestionActivated", "onContentBlockValid", "onContentBlockInvalid"])
          ], 2))
        }), 256)),
        (_ctx.answers.length === _ctx.totalLength)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "footer__next-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.publishTest()))
            }, "Далее"))
          : _createCommentVNode("", true)
      ], 512)
    ], 512)
  ], 2))
}