export interface ContentBlock {
    contents: ContentDescription[];
    questions: FullQuestion[];
}

export interface ContentDescription {
    content: string;
    order: number;
}

export interface Question {
    title: string;
    errorMessage: string;
    rightAnswer: string;
    order: number;
    answerVariants: AnswerVariant[];
    type: QuestionTypeEnum;
}

export interface FullQuestion extends Question {
    id: number;
    activated: boolean;
    answer: string;
}

export interface AnswerVariant {
    title: string;
    value: string;
}

export enum QuestionTypeEnum {
    DEFAULT = 'default',
    BOLD = 'bold',
    BINARY = 'binary'
}

export interface Answer {
    questionId: number;
    answer: string;
    answerHtml: string;
    answerMeta: string;
}
