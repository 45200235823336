import 'core-js';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueCookieNext from 'vue-cookie-next'

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + '/api/v1';

createApp(App)
    .use(router)
    // @ts-ignore
    .use(VueCookieNext)
    .mount('#app')

axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error);
});


