import axios from "axios";
import {VueCookieNext} from "vue-cookie-next";
import {ResultStepEnum} from "@/schema/result.model";

export default class ResultsService {
    checkResultInStorage(): string | null {
        const resultId = JSON.parse(localStorage.getItem('resultId'));
        if (resultId) {
            return resultId;
        }
        return null;
    }

    checkResultInCookie(): string | null {
        const resultId = VueCookieNext.getCookie('resultId');
        if (resultId) {
            return resultId;
        }
        return null;
    }

    isResultExists(): string | null {
        const resultInStorage = this.checkResultInStorage();
        const resultInCookie = this.checkResultInCookie();

        if (resultInStorage) {
            return resultInStorage;
        } else if (resultInCookie) {
            return resultInCookie;
        } else {
            return null;
        }
    }

    putResultIdToHeaders(resultId: string): void {
        axios.defaults.headers.common['X-HTTP-PSTF-RI'] = resultId;
    }

    createResult(testId: string): Promise<any> {
        return axios.post(`/results`, {testCode: testId.length > 0 ? testId : null});
    }

    getProgress(): Promise<any> {
        return axios.get(`/result/progress`);
    }

    getPDF(): Promise<any> {
        return axios.get(`/result/pdf`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            params: {
                type: 'test'
            }
        });
    }

}
