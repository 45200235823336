
import {Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {AnswerVariant} from "@/schema/content.model";

export default class QuestionAnswerVariants extends Vue {
  @Prop({type: Array, default: null}) variants: AnswerVariant[];
  @Prop({type: String, default: null}) presetValue: null | string = null;
  @Watch('presetValue', {immediate: true, deep: true})
  onPresetValueChanged(newVal: string, oldVal: string): void {
    if (newVal && newVal !== oldVal) {
      this.activeValue = newVal;
      if (!this.disabled) {
        this.$emit('variant-thrown', this.activeValue);
      }
    }
  }

  activeValue: null | string = null;
  disabled = false;

  setBinaryAnswer(value: string, event?: MouseEvent): void {
    event.preventDefault();

    const eventTarget = event.target as HTMLElement;
    const blockParent = eventTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    this.disabled = blockParent.classList.contains('blocked');

    if (!this.disabled) {
      this.activeValue = value;
      this.$emit('variant-thrown', value);
    }
  }
}
