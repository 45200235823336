
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {FullQuestion, QuestionTypeEnum} from "@/schema/content.model";
import TheEditor from "@/shared/components/TheEditor.vue";
import QuestionAnswerVariants from "@/shared/components/QuestionAnswerVariants.vue";
import ErrorMessage from "@/shared/components/ErrorMessage.vue";

@Options({
  components: {
    TheEditor,
    QuestionAnswerVariants,
    ErrorMessage
  },
})
export default class Question extends Vue {
  questionTypeEnum = QuestionTypeEnum;
  error: string | null = null;
  isEditorVisible = false;
  questionValid = false;
  @Prop({type: Object, default: null}) question: FullQuestion;
  @Prop({type: Boolean, default: false}) disabled: null | boolean = null;
  @Prop({type: Boolean, default: false}) lastQuestion: null | boolean = false;

  activateQuestion(event: MouseEvent): void {
    if (!this.question.activated) {
      this.question.activated = true;
      const questionElement = this.$refs.question as HTMLElement;
      this.$emit('question-activated', {questionElement, clientY: event.clientY});
    }

    // Delete code under when it will become useless (now using for blocking previous binary question if current binary is activated)
    const currentQuestion = event.target as HTMLElement;
    const prevQuestion = currentQuestion.parentElement.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
    if (prevQuestion?.classList.contains('content-block__wrapper')) {
      prevQuestion.classList.add('blocked');
    }
  }

  validateBinaryAnswer(value: string): void {
    if (value === this.question.rightAnswer) {
      this.error = null;
      this.question.answer = value;
      this.questionValid = true;
      this.$emit('question-valid', this.question);
    } else {
      this.error = this.question.errorMessage;
      this.questionValid = false;
      this.$emit('question-invalid', {id: this.question.id, answer: value});
    }
  }


  validateEditorValue(value: string): void {
    const isEditorValueValid = this.checkIsEditorValueValid(value);
    if (isEditorValueValid) {
      this.question.answer = value;
      this.questionValid = true;
      this.$emit('question-valid', this.question);
    } else {
      this.questionValid = false;
      this.$emit('question-invalid', {id: this.question.id, answer: ''});
    }
  }

  deactivateQuestion(value: string): void {
    const isEditorValueValid = this.checkIsEditorValueValid(value);
    if (isEditorValueValid) {
      this.validateEditorValue(value);
    }
    if (this.lastQuestion && !isEditorValueValid) {
      this.questionValid = false;
      this.question.activated = false;
    }
  }


  // emptyQuestionLostFocus(value: string): void {
  //   const isEditorValueValid = this.checkIsEditorValueValid(value);
  //   if (!isEditorValueValid) {
  //     this.question.activated = false;
  //     const questionElement = this.$refs.question as HTMLElement;
  //     this.$emit('empty-question-lost-focus', questionElement.offsetHeight);
  //   }
  // }

  checkIsEditorValueValid(value: string): boolean { // TODO: Move to the service
    const blockNode = document.createElement('html');
    blockNode.innerHTML = value;
    const valueNodes = Array.from(blockNode.getElementsByTagName('*')).slice(2);
    return !valueNodes.every(this.isNodeEmpty);
  }

  isNodeEmpty(element) { // TODO: Move to the service
    return element.innerText.length === 0;
  }

}
